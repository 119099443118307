import NProgress from 'nprogress'
import {router} from '@inertiajs/vue3'
import {ref} from "vue";

export const router_progress = ref(false);

export function initLoader(){
    let timeout = null
    NProgress.configure({
        template: '<div class="fixed top-0 h-1 bg-gradient-to-r to-driftshop-500 from-rose-400  w-screen !z-[5000]" role="bar"></div> ' +
            '<div class="fixed !z-[5000]' +
            ' bg-white/20 rounded-md md:backdrop-blur-sm h-10' +
            ' bottom-5' +
            ' right-5 text-driftshop text-3xl flex justify-center items-center">' +
            '<img src="/images/logo/default.png" class="animate-spin animate-infinite animate-duration-[1500ms] !z-[5000] animate-ease-in-out animate-normal h-8 w-8' +
            ' mr-2">' +
            '<span class="text-sm !z-[5000]">Chargement ...</span>  ' +
            '</div>',
        minimum: 0.33,
        maximum: 0.8,
        easing: 'ease',
        speed: 400,
        showSpinner: false
    })

    router.on('start', () => {
        router_progress.value = true;
        clearTimeout(timeout)
        timeout = setTimeout(() => NProgress.start(), 10)
    })
    router.on('finish', () => {
        router_progress.value = false;
        NProgress.done()
    })
    router.on('progress', (event) => {
        if (event.detail.progress.percentage) {
            NProgress.set((event.detail.progress.percentage / 100) )
        }
    })
}
